import React, {Component} from 'react';
import $ from 'jquery';
import {baseUrl} from "../constants";

class Vehicles extends Component {
    state = {
        lang: this.props.match.params.lang,
        apiUrl: baseUrl + (this.props.match.params.lang !== 'ru' ? this.props.match.params.lang + '/' : ''),

        titles: {},
        content: {},
        vehicles: [],
    };
    componentDidMount() {
        this.initPage();
        $('.navbar-collapse').collapse('hide');
        $('.carousel').carousel({
            touch: true
        });
    }
    initPage() {
        $('#loader').show();
        fetch(this.state.apiUrl + 'api/init-mobile-page/vehicles')
            .then(response => response.json())
            .then(data => {
                $('#loader').hide();
                this.setState({titles: data.translations});
                this.setState({content: data.content});
                this.setState({vehicles: data.vehicles});
            });
    };
    render() {
        const {titles, vehicles} = this.state;
        return (
            <div className='pageContent'>
                <h1 className='page-title'>{titles.title}</h1>
                <div className="vehicles-carousel">
                    <div id="vehicles-carousel" className="carousel slide" data-ride="carousel">
                        <div className="carousel-inner">
                            {vehicles.map((vehicle, index) => (
                                <div className={'carousel-item ' +(index === 0 ? 'active' : '')} key={index}>
                                    <img src={ '/images/mobile/vehicles/vehicle-'+vehicle.id+'.png'} className="d-block w-100" alt="..."/>
                                    <div className="vehicle-name">{vehicle.example}</div>
                                    <div className="vehicle-desc">{vehicle.description}</div>
                                </div>
                            ))}
                        </div>
                        <ol className="carousel-indicators" style={{bottom: '-70px'}}>
                            {vehicles.map((vehicle, index) => (
                                <li data-target="#vehicles-carousel" data-slide-to={index} className={(index === 0 ? 'active' : '')} key={index}></li>
                            ))}
                        </ol>
                    </div>
                </div>
            </div>
        );
    }
}

export default Vehicles;
