import React, {Component} from 'react';
import {NavLink} from "react-router-dom";

class DirectionsList extends Component {
    render() {
        const direction = this.props.direction;
        let DirectionItem = (props) => (
            <NavLink
                to={
                    '/' + this.props.lang+ 
                    '/transfer?' +
                    'from=' +
                    direction.fromId +
                    '&' +
                    'to=' +
                    props.destination.id
                }
                className="direction-item">
                <div className="direction-name">
                    <i className="fa fa-map-marker-alt"/>
                    {props.destination.title}
                </div>
                <div className="direction-price">от {props.destination.minPrice} €</div>
            </NavLink>
        );

        return (
            <div className="directions-list">
                <div className="directions-title">{direction.fromTitle} </div>
                {
                    direction.destinations.map((dest, index) => (
                        <DirectionItem destination={dest} key={index}/>
                    ))
                }
            </div>
        );
    }
}

export default DirectionsList;
