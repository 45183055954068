import React, {Component} from 'react';
import $ from 'jquery';
import {baseUrl} from "../constants";
import DirectionsList from '../components/DirectionsList'

class Directions extends Component {
    state = {
        lang: this.props.match.params.lang,
        apiUrl: baseUrl + (this.props.match.params.lang !== 'ru' ? this.props.match.params.lang + '/' : ''),
        titles: {},
        directions: []
    };
    componentDidMount = () => {
        $('.navbar-collapse').collapse('hide');
        this.initPage();
    };
    initPage() {
        $('#loader').show();
        fetch(this.state.apiUrl + 'api/init-mobile-page/directions')
            .then(response => response.json())
            .then(data => {
                $('#loader').hide();
                this.setState({titles: data.translations});
                this.setState({directions: data.directions});
            });
    };

    render() {
        const {titles, directions} = this.state;
        return (
            <div className='pageContent'>
                <h1 className='page-title'>{titles.title}</h1>
                {
                    directions.map((direction) =>(
                        <DirectionsList direction={direction} lang={this.state.lang} key={direction.fromId}/>
                    ))
                }
            </div>
        );
    }
}

export default Directions;
