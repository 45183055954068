import React, {Component} from 'react';
import $ from 'jquery';
import {baseUrl} from "../constants";

class About extends Component {

    state = {
        lang: this.props.match.params.lang,
        apiUrl: baseUrl + (this.props.match.params.lang !== 'ru' ? this.props.match.params.lang + '/' : ''),
        titles: {},
        content: {},
    };
    componentDidMount() {
        console.log(this.props)
        this.initPage();
        $('.navbar-collapse').collapse('hide');
    }
    initPage() {
        $('#loader').show();
        fetch(this.state.apiUrl + 'api/init-mobile-page/about')
            .then(response => response.json())
            .then(data => {
                $('#loader').hide();
                this.setState({titles: data.translations});
                this.setState({content: data.content});
            });
    };
    render() {
        const {titles, content} = this.state;
        return (
            <div className='pageContent'>
                <h1 className='page-title'>{titles.title}</h1>
                <div dangerouslySetInnerHTML={{__html: content.general}}/>
                <div className="benefits-title">{content.steps}</div>
                <div className="block-with-icon">
                    <img src="/images/mobile/about-step-1.svg" alt=""/>
                    <p>{content.step_1}</p>
                </div>
                <div className="block-with-icon">
                    <img src="/images/mobile/about-step-2.svg" alt="" />
                    <p>{content.step_2}</p>
                </div>
                <div className="block-with-icon">
                    <img src="/images/mobile/about-step-3.svg" alt="" />
                    <p>{content.step_3}</p>
                </div>
                <div className="block-with-icon">
                    <img src="/images/mobile/about-step-4.svg" alt="" />
                    <p>{content.step_4}</p>
                </div>

            </div>
        );
    }
}

export default About;
