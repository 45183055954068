import React, { Component } from 'react';
import { baseUrl } from '../constants';
import $ from 'jquery';

class Home extends Component {
    state = {
        lang: this.props.match.params.lang,
        apiUrl: baseUrl + (this.props.match.params.lang !== 'ru' ? this.props.match.params.lang + '/' : ''),

        inputFrom: '',
        inputFromPlaceholder: '',
        inputTo: '',
        inputToPlaceholder: '',
        defFromId: null,
        defToId: null,
        fromId: null,
        toId: null,
        fromWait: false,
        toWait: false,
        fromList: [],
        toList: [],
        titles: {},
        content: {}
    };

    componentWillMount() {
    }

    componentDidMount() {
        this.initPage();
        $('.navbar-collapse').collapse('hide');
    }
    initPage() {
        $('#loader').show();
        fetch(this.state.apiUrl + 'api/init-mobile-page/home')
            .then(response => response.json())
            .then(data => {
                $('#loader').hide();
                this.setState({ titles: data.translations });
                this.setState({ content: data.content });

                this.setState({ inputFromPlaceholder: data.searchFrom.inputFrom });
                this.setState({ inputToPlaceholder: data.searchFrom.inputTo });
                this.setState({ defFromId: data.searchFrom.fromId });
                this.setState({ defToId: data.searchFrom.toId });
            });
    };

    handleChangeFrom = (event) => {
        this.setState({ inputFrom: event.target.value });
        this.setState({ fromWait: true });
        this.getLocations(event.target.value, 'from');
    };
    handleChangeTo = (event) => {
        this.setState({ inputTo: event.target.value });
        this.setState({ toWait: true });
        this.getLocations(event.target.value, 'to');
    };

    async getLocations(str, field) {
        await fetch(this.state.apiUrl + 'api/get-locations-ac?value=' + str)
            .then(response => response.json())
            .then(data => {
                switch (field) {
                    case 'from':
                        this.setState({ fromList: data });
                        this.setState({ fromId: null });
                        this.setState({ fromWait: false });
                        break;
                    case 'to':
                        this.setState({ toList: data });
                        this.setState({ toId: null });
                        this.setState({ toWait: false });
                        break;
                    default:
                }
            });
    };

    choiceLocation = (value, locId, field) => {
        switch (field) {
            case 'from':
                this.setState({ fromId: locId });
                this.setState({ inputFrom: value });
                this.setState({ fromList: [] });
                break;
            case 'to':
                this.setState({ toId: locId });
                this.setState({ inputTo: value });
                this.setState({ toList: [] });
                break;
            default:
        }
    };

    searchBtnClick = () => {
        let from = null;
        let to = null;
        if (this.state.fromId) {
            from = this.state.fromId;
        } else if (this.state.inputFrom) {
            from = this.state.inputFrom;
        }
        if (this.state.toId) {
            to = this.state.toId;
        } else if (this.state.inputTo) {
            to = this.state.inputTo;
        }
        if (!from) {
            from = this.state.defFromId
        }
        if (!to) {
            to = this.state.defToId
        }

        if (from && to) {
            this.props.history.push(
                '/' + this.state.lang +
                '/transfer?' +
                'from=' +
                from +
                '&' +
                'to=' +
                to
            )
        }
    };

    reverseBtn = () => {
        let from = {
            id: this.state.fromId,
            text: this.state.inputFrom,
            defId: this.state.defFromId,
            placeholder: this.state.inputFromPlaceholder,
        }
        let to = {
            id: this.state.toId,
            text: this.state.inputTo,
            defId: this.state.defToId,
            placeholder: this.state.inputToPlaceholder,
        }

        this.setState({ fromId: to.id });
        this.setState({ inputFrom: to.text });
        this.setState({ inputFromPlaceholder: to.placeholder });
        this.setState({ defFromId: to.defId });
        this.setState({ toId: from.id });
        this.setState({ inputTo: from.text });
        this.setState({ inputToPlaceholder: from.placeholder });
        this.setState({ defToId: from.defId });
    }

    render() {
        const { titles, content } = this.state;

        let Autocomplete = (props) => (
            <div className="autocomplete-values-wrapper">
                <div className="autocomplete-values">
                    {props.locations.map((location, index) => (
                        <div
                            onClick={() => this.choiceLocation(location.trans ? location.trans : location.title, location.id, props.field)}
                            className="autocomplete-item"
                            key={index}>{location.trans ? location.trans : location.title}</div>))}
                </div>
            </div>
        );

        return (
            <div className='pageContent'>
                <div className="pageBg yellow-bg" />
                <h1 className='page-title'>{titles.title}</h1>
                <p className='text-center strong' dangerouslySetInnerHTML={{ __html: titles.desc }}></p>
                <br />
                <div className="search-form">
                    <div className="form-row">
                        <label className='text-field-label'>{titles.from_label}</label>
                        <input type="text" className='form-control text-field' value={this.state.inputFrom}
                            placeholder={this.state.inputFromPlaceholder}
                            onChange={this.handleChangeFrom} />
                        {this.state.fromWait ? <i className="fa fa-spinner fa-pulse fa-fw input-loader"></i> : ''}
                        {this.state.fromList.length > 0 ?
                            <Autocomplete locations={this.state.fromList} field="from" /> : null}
                    </div>
                    <div className="text-center">
                        <i onClick={this.reverseBtn} className="fa fa-sync form-reverse" />
                    </div>
                    <div className="form-row">
                        <label className='text-field-label'>{titles.to_label}</label>
                        <input type="text" className='form-control text-field' value={this.state.inputTo}
                            placeholder={this.state.inputToPlaceholder}
                            onChange={this.handleChangeTo} />
                        {this.state.toWait ? <i className="fa fa-spinner fa-pulse fa-fw input-loader"></i> : ''}
                        {this.state.toList.length > 0 ? <Autocomplete locations={this.state.toList} field="to" /> : null}
                    </div>
                    <br />
                    <div className="text-center">
                        <button onClick={this.searchBtnClick} className="btn btn-green">{titles.search} <i
                            className="fa fa-search" /></button>
                    </div>
                </div>
                <br />
                <h2 className='page-title'>{content.advantages_title}</h2>
                <div className="benefits-title">{content.advantages_general}</div>
                <div className="block-with-icon">
                    <img src="/images/mobile/mercedes.svg" alt="" />
                    <p className="strong">{content.advantages_1}</p>
                </div>
                <div className="block-with-icon">
                    <img src="/images/mobile/driver.svg" alt="" />
                    <p className="strong">{content.advantages_2}</p>
                </div>
                <div className="block-with-icon">
                    <img src="/images/mobile/cash-payment.svg" alt="" />
                    <p className="strong">{content.advantages_3}</p>
                </div>
                <div className="block-with-icon">
                    <img src="/images/mobile/child.svg" alt="" />
                    <p className="strong">{content.advantages_4}</p>
                </div>
                <div className="block-with-icon">
                    <img src="/images/mobile/airplane.svg" alt="" />
                    <p className="strong">{content.advantages_5}</p>
                </div>
                <br />
                <h2 className='page-title'>{content.about_title}</h2>
                <div dangerouslySetInnerHTML={{ __html: content.about_general }} />
                <div className="benefits-title">{content.steps}</div>
                <div className="block-with-icon">
                    <img src="/images/mobile/about-step-1.svg" alt="" />
                    <p className="strong">{content.step_1}</p>
                </div>
                <div className="block-with-icon">
                    <img src="/images/mobile/about-step-2.svg" alt="" />
                    <p className="strong">{content.step_2}</p>
                </div>
                <div className="block-with-icon">
                    <img src="/images/mobile/about-step-3.svg" alt="" />
                    <p className="strong">{content.step_3}</p>
                </div>
                <div className="block-with-icon">
                    <img src="/images/mobile/about-step-4.svg" alt="" />
                    <p className="strong">{content.step_4}</p>
                </div>
                <h2 className='page-title'>{content.reviews_title}</h2>
                <div className="review-carousel">
                    <div id="review-carousel" className="carousel slide" data-ride="carousel">
                        <div className="carousel-inner">
                            {content.reviews_items ? content.reviews_items.map((review, index) => (
                                <div className={index === 0 ? 'carousel-item active' : 'carousel-item'} key={index}>
                                    <div className="review-item">
                                        <div className="review-content">{review.content}</div>
                                        <div className="review-author">{review.author}</div>
                                    </div>
                                </div>

                            )) : ''}
                        </div>
                        <ol className="carousel-indicators">
                            {content.reviews_items ? content.reviews_items.map((review, index) => (
                                <li data-target="#review-carousel" data-slide-to={index} className={index === 0 ? 'active' : ''} key={index}></li> 
                            )) : ''}
                        </ol>
                    </div>
                </div>

            </div>
        );
    }
}

export default Home;
