/* eslint-disable no-useless-constructor */
import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import './App.css';
import $ from 'jquery';
import { baseUrl } from "./constants";


const supportedLanguages = ["en", "ru"];

class App extends Component {
  state = {
    lang: this.props.lang,
    apiUrl: baseUrl + (this.props.lang !== 'ru' ? this.props.lang + '/' : ''),
    phoneShowed: false,
    lSwitcherShowed: false,
    translate: {
      callAs: '',
      menuItems: {
        about: '',
        vehicles: '',
        directions: '',
        benefits: '',
        reviews: '',
        faq: '',
        contacts: '',
      }
    }
  }
  componentWillMount = () => {
    this.appInit()
    this.hideAll()
  }
  appInit = () => {
    // $('#loader').show();
    fetch(this.state.apiUrl + 'api/init-mobile-page/app')
      .then(response => response.json())
      .then(data => {
        // $('#loader').hide();
        this.setState({ translate: data.translations });
      });
  }
  togglePhoneShow = () => {
    let curPhSh = this.state.phoneShowed;
    this.setState({ phoneShowed: !curPhSh, lSwitcherShowed: false })
    $('.collapse').collapse('hide')
  }
  toggleLangSwitcher = () => {
    let curLSSH = this.state.lSwitcherShowed;
    this.setState({
      phoneShowed: false,
      lSwitcherShowed: !curLSSH
    })
    $('.collapse').collapse('hide')
  }
  toggleNav = () => {
    this.setState({
      phoneShowed: false,
      lSwitcherShowed: false
    })
  }
  hideAll = () => {
    $('.collapse').collapse('hide')
    this.setState({
      phoneShowed: false,
      lSwitcherShowed: false
    })
  }
  changeLang = (newLang) => {
    let childProps = this.props.children.props;
    let newUrl = childProps.match.path.replace('/:lang', '/' + newLang);
    window.location.replace(newUrl + childProps.location.search);
  }
  render() {
    const { lang, translate } = this.state;
    return (
      <div className={'App'}>
        <nav className="navbar navbar-light bg-transparent">
          <NavLink to={'/' + lang} onClick={this.hideAll} className="nav-link" activeClassName="active" exact={true}><img src="/images/mobile/logo.png" height="30" alt="" /></NavLink>
          <span onClick={this.togglePhoneShow} className={'phone-btn ' + (this.state.phoneShowed ? 'showed' : '')}><i className="fa fa-phone"></i></span>
          {this.state.phoneShowed ? (
            <div className="phonePopup">
              <div className="corner"></div>
              <div>{translate.callAs}</div>
              <a href="tel:+420777507774">+420 777 507 774</a>
            </div>
          ) : ''
          }
          <button onClick={this.toggleNav} className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
            aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon" />
          </button>
          < span onClick={
            this.toggleLangSwitcher
          }
            className="langSwitcher" > {lang} </span>
          {this.state.lSwitcherShowed ? (
            <div className="langList">
              {supportedLanguages.map((l, index) => l !== lang ? <span className="langItem" onClick={() => { this.changeLang(l) }} key={index}>{l}</span> : '')}
            </div>
          ) : ''
          }
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav">
              <li className="nav-item">
                <NavLink
                  to={"/" + lang + "/about"}
                  className="nav-link"
                  activeClassName="active"
                >{translate.menuItems.about}</NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to={"/" + lang + "/vehicles"}
                  className="nav-link"
                  activeClassName="active"
                >{translate.menuItems.vehicles}</NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to={"/" + lang + "/directions"}
                  className="nav-link"
                  activeClassName="active"
                >{translate.menuItems.directions}</NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to={"/" + lang + "/benefits"}
                  className="nav-link"
                  activeClassName="active"
                >{translate.menuItems.benefits}</NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to={"/" + lang + "/reviews"}
                  className="nav-link"
                  activeClassName="active"
                >{translate.menuItems.reviews}</NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to={"/" + lang + "/faq"}
                  className="nav-link"
                  activeClassName="active"
                >{translate.menuItems.faq}</NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to={"/" + lang + "/contacts"}
                  className="nav-link"
                  activeClassName="active"
                >{translate.menuItems.contacts}</NavLink>
              </li>
            </ul>
          </div>
        </nav>
        <div className="pageBody" onClick={this.hideAll}>
          {this.props.children}
          <footer>
            <div className="footer-row">
              <div className="footer-icons">
                <div className="logo" />
                <div className="insta"><i className="fab fa-instagram" /></div>
                <div className="clearfix" />
              </div>
            </div>
            <div className="copyright">Asistour Travel Agency s.r.o. © 2023 <br /> All rights reserved</div>
          </footer>
          {/*<Route path="/topics" component={Topics} />*/}
        </div>
        <div id="loader">
          <i className="fa fa-spinner fa-pulse fa-fw" />
        </div>
      </div>
    );
  }
}

export default App;
