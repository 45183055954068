import React, { Component } from 'react';
import { baseUrl } from '../constants';
import $ from 'jquery';

class Contact extends Component {
    state = {
        lang: this.props.match.params.lang,
        apiUrl: baseUrl + (this.props.match.params.lang !== 'ru' ? this.props.match.params.lang + '/' : ''),

        titles: {},
        content: {}
    }
    componentDidMount() {
        this.initPage();
        $('.navbar-collapse').collapse('hide');
    }
    initPage() {
        $('#loader').show();
        fetch(this.state.apiUrl + 'api/init-mobile-page/contacts')
            .then(response => response.json())
            .then(data => {
                $('#loader').hide();
                this.setState({ titles: data.translations });
                this.setState({ content: data.content });
            });
    };
    render() {
        const { titles, content } = this.state;
        return (
            <div className='pageContent'>
                <h1 className='page-title'>{titles.title}</h1>
                <p className='text-center' dangerouslySetInnerHTML={{ __html: content.general }}></p>
                <div className="contact-form">
                    <input className="form-control text-field" type="text" placeholder={content.form_name} />
                    <br />
                    <input className="form-control text-field" type="text" placeholder={content.form_phone} />
                    <br />
                    <input className="form-control text-field" type="text" placeholder={content.form_email} />
                    <br />
                    <textarea className="form-control text-field" placeholder={content.form_text} style={{ height: 200 }}></textarea>
                </div>
                <div className="text-center">
                    <button className="btn btn-green">{content.form_send}</button>
                </div>
            </div>
        );
    }
}

export default Contact;
