import React, { Component } from 'react';
import { baseUrl } from '../constants';
import $ from 'jquery';

class Reviews extends Component {
    state = {
        lang: this.props.match.params.lang,
        apiUrl: baseUrl + (this.props.match.params.lang !== 'ru' ? this.props.match.params.lang + '/' : ''),

        titles: {},
        content: {}
    }
    componentDidMount() {

        this.initPage();
        $('.navbar-collapse').collapse('hide');
        $('.carousel').carousel({
            touch: true
        });

    }
    initPage() {
        $('#loader').show();
        fetch(this.state.apiUrl + 'api/init-mobile-page/reviews')
            .then(response => response.json())
            .then(data => {
                $('#loader').hide();
                this.setState({ titles: data.translations });
                this.setState({ content: data.content });
            });
    }
    render() {
        const { titles, content } = this.state;
        return (
            <div className='pageContent'>
                <h1 className='page-title'>{titles.title}</h1>
                <div className="review-carousel">
                    <div id="review-carousel" className="carousel slide" data-ride="carousel">
                        <div className="carousel-inner">
                            {content.reviews_items ? content.reviews_items.map((review, index) => (
                                <div className={index === 0 ? 'carousel-item active' : 'carousel-item'} key={index}>
                                    <div className="review-item">
                                        <div className="review-content">{review.content}</div>
                                        <div className="review-author">{review.author}</div>
                                    </div>
                                </div>

                            )) : ''}
                        </div>
                        <ol className="carousel-indicators">
                            {content.reviews_items ? content.reviews_items.map((review, index) => (
                                <li data-target="#review-carousel" data-slide-to={index} className={index === 0 ? 'active' : ''} key={index}></li>
                            )) : ''}
                        </ol>
                    </div>
                </div>
            </div>
        );
    }
}

export default Reviews;
