import React, { Component } from 'react';
import $ from 'jquery';
import { baseUrl } from "../constants";
import { Redirect } from "react-router";
class OrderConfirm extends Component {
    state = {
        lang: this.props.match.params.lang,
        apiUrl: baseUrl + (this.props.match.params.lang !== 'ru' ? this.props.match.params.lang + '/' : ''),

        confirm: true,
        translate: {
            title: '',
            content: '',
        },
    };
    componentWillMount() {
        this.getData();
        $('.navbar-collapse').collapse('hide');
    }
    getData() {
        $('#loader').show();
        fetch(this.state.apiUrl + 'api/order-confirm' + this.props.location.search)
            .then(response => response.json())
            .then(data => {
                $('#loader').hide();
                this.setState({ translate: data.translations });
                if (!data.confirm) {
                    this.setState({ confirm: false });
                }
            });
    };

    render() {
        let view = (
            <div className='pageContent'>
                <h1 className='page-title'>{this.state.translate.title}</h1>
                <div dangerouslySetInnerHTML={{ __html: this.state.translate.content }}></div>
            </div>
        );
        if (!this.state.confirm) {
            view = (
                <Redirect to="/" />
            );

        }
        return (
            <div>{view}</div>
        );
    }
}

export default OrderConfirm;
