import 'rc-time-picker/assets/index.css';
import React, { Component } from 'react';
import TimePicker from 'rc-time-picker';
import Switch from 'react-ios-switch';
import $ from 'jquery';
import { baseUrl } from "../constants";
import { Redirect } from "react-router";

class OrderFrom extends Component {
    state = {
        lang: this.props.match.params.lang,
        apiUrl: baseUrl + (this.props.match.params.lang !== 'ru' ? this.props.match.params.lang + '/' : ''),

        orderConfirm: false,
        orderToken: null,
        plateChanged: false,
        freeChildSeats: 0,
        transfer: {
            from: {
                id: null,
                title: null,
            },
            to: {
                id: null,
                title: null,
            },
            type: 'p-p',
            seatPrice: null,
            duration: null,
            vehicle: {
                id: null,
                title: null,
            },
        },
        order: {
            currentLang: 'ru',
            direction: null,
            from_id: null,
            from: null,
            to_id: null,
            to: null,
            serviceType: 'individual',
            type: 'p-p',
            vehicle_id: null,
            child: false,
            child_small: 0,
            child_medium: 0,
            child_big: 0,
            return_transfer: false,
            pick_up: null,
            pick_up_date: null,
            pick_up_time: null,
            drop_off: null,
            return_date: null,
            return_time: null,
            pax: 1,
            flight: null,
            contact_name: null,
            contact_phone: null,
            contact_email: null,
            comment: null,
            plate: '',
            totalPrice: 0
        },
        errors: {
            pick_up_date: false,
            pick_up_time: false,
            pick_up: false,
            flight: false,
            drop_off: false,
            return_date: false,
            return_time: false,
            contact_name: false,
            contact_phone: false,
            contact_email: false
        },
        translate: { orderForm: {} },
    };

    componentWillMount = () => {
        this.getData();
        $('.navbar-collapse').collapse('hide');
    };

    getData() {
        $('#loader').show();
        fetch(this.state.apiUrl + 'api/transfer-order' + this.props.location.search)
            .then(response => response.json())
            .then(data => {
                $('#loader').hide();
                this.setState({ transfer: data.transfer });
                this.setState({ translate: { orderForm: data.translations.orderForm } });
                this.setState(
                    {
                        order: {
                            ...this.state.order,
                            ...{
                                vehicle_id: data.transfer.vehicle.id,
                                from_id: data.transfer.from.id,
                                from: data.transfer.from.title,
                                to_id: data.transfer.to.id,
                                to: data.transfer.to.title,
                                direction: data.transfer.direction,
                                type: data.transfer.type,
                                serviceType: data.transfer.serviceType
                            }
                        }
                    }
                );
                $('html,body').scrollTop(0);
            });
    };

    orderFormSubmit = () => {
        if (this.orderformValidate()) {
            this.sendOrder();
        } else {
            // console.log(this.state.errors);
        }
    };
    sendOrder = () => {
        $('#loader').show();
        $.post(this.state.apiUrl + 'api/order-save',
            this.state.order,
        ).then(function (response) {
            if (response.status === 'success') {
                this.setState(
                    { orderConfirm: true, orderToken: response.order_code, }
                )
            }
            $('#loader').hide();
        }.bind(this), function (error) {
            $('#loader').hide();
        });
    };
    orderformValidate = () => {
        const { order } = this.state;
        let errors = this.state;
        let valid = false;

        if (order.contact_name && $.trim(order.contact_name)) {
            errors.contact_name = false;
            valid = true;
        } else {
            errors.contact_name = true;
            valid = false;
        }
        if (order.contact_phone && $.trim(order.contact_phone)) {
            errors.contact_phone = false;
        } else {
            errors.contact_phone = true;
            valid = false;
        }
        if (order.contact_email && $.trim(order.contact_email)) {
            if (this.validateEmail(order.contact_email)) {
                errors.contact_email = false;
            } else {
                errors.contact_email = true;
                valid = false;
            }
        } else {
            errors.contact_email = true;
            valid = false;
        }
        if (order.pick_up_date && $.trim(order.pick_up_date)) {
            errors.pick_up_date = false;
        } else {
            errors.pick_up_date = true;
            valid = false;
        }
        if (order.pick_up_time && $.trim(order.pick_up_time)) {
            errors.pick_up_time = false;
        } else {
            errors.pick_up_time = true;
            valid = false;
        }

        if (this.state.type === 'p-p') {
            if (order.pick_up && $.trim(order.pick_up)) {
                errors.pick_up = false;
            } else {
                errors.pick_up = true;
                valid = false;
            }
            if (order.drop_off && $.trim(order.drop_off)) {
                errors.drop_off = false;
            } else {
                errors.drop_off = true;
                valid = false;
            }
        }
        if (this.state.type === 'p-s') {
            if (order.pick_up && $.trim(order.pick_up)) {
                errors.pick_up = false;
            } else {
                errors.pick_up = true;
                valid = false;
            }
        }
        if (this.state.type === 's-p') {
            if (order.drop_off && $.trim(order.drop_off)) {
                errors.drop_off = false;
            } else {
                errors.drop_off = true;
                valid = false;
            }
            if (order.flight && $.trim(order.flight)) {
                errors.flight = false;
            } else {
                errors.flight = true;
                valid = false;
            }
        }
        if (order.return_transfer) {
            if (order.return_date && $.trim(order.return_date)) {
                errors.return_date = false;
            } else {
                errors.return_date = true;
                valid = false;
            }
            if (order.return_time && $.trim(order.return_time)) {
                errors.return_time = false;
            } else {
                errors.return_time = true;
                valid = false;
            }

            if (errors.return_date) {
                let d = this.order.params.date + '';
                let rd = this.order.params.backDate + '';

                let aD = d.split('.');
                let aRD = rd.split('.');
                let tD = new Date(aD[2] + '-' + aD[1] + '-' + aD[0]).getTime() / 1000;
                let tRD = new Date(aRD[2] + '-' + aRD[1] + '-' + aRD[0]).getTime() / 1000;

                if (parseInt(tD) < parseInt(tRD)) {
                    errors.return_date = false;
                } else {
                    errors.return_date = true;
                    valid = false;
                }
            }
        }
        this.setState({ errors: errors });
        return valid;
    };

    validateEmail(email) {
        let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    };
    changePax(pax) {
        this.setState({
            order: { ...this.state.order, pax: pax, child_small: 0, child_medium: 0, child_big: 0 }
        })
        // this.calcFreeSeats()
    }
    calcFreeSeats() {
        let freeChildSeats = parseInt(this.state.order.pax) - parseInt(this.state.order.child_small) - parseInt(this.state.order.child_medium) - parseInt(this.state.order.child_big) - 1;
        this.setState({ freeChildSeats: freeChildSeats })
    }

    render() {
        const { transfer, order, errors, translate } = this.state;
        let tmpPax = [];
        for (let i = 1; i <= transfer.vehicle.paxI; i++) {
            tmpPax.push(i);
        }

        let freeChildSeats = order.pax - order.child_small - order.child_medium - order.child_big - 1;
        let freeChSelOpts = [];
        for (let i = 1; i <= order.pax - 1; i++) {
            freeChSelOpts.push(i);
        }

        let childSelects = '';
        if (order.child && order.pax > 1) {

            childSelects = (
                <div>
                    {freeChildSeats > 0 || order.child_small > 0 ?
                        <div className="form-row">
                            <div className="form-label">{translate.orderForm.fields ? translate.orderForm.fields.baby_seats_small.label : null}</div>
                            <div className="form-select">
                                <select className="childSelect"
                                    style={{ backgroundImage: "url('/images/mobile/childSeat-1-icon.svg')" }}
                                    onChange={event => {
                                        this.setState(
                                            { order: { ...order, child_small: event.target.value <= freeChildSeats ? event.target.value : freeChildSeats } }
                                        )
                                    }}
                                    value={order.child_small}>
                                    <option value={null} ></option>
                                    {freeChSelOpts.map((p, i) => (
                                        <option value={p} key={i}>{p}</option>
                                    ))}
                                </select>
                                <div className="select-caret" />
                            </div>
                            <div className="form-input-desc">{translate.orderForm.fields ? translate.orderForm.fields.baby_seats_small.desc : null}</div>
                        </div>
                        : ''}
                    {freeChildSeats > 0 || order.child_medium > 0 ?
                        <div className="form-row">
                            <div className="form-label">{translate.orderForm.fields ? translate.orderForm.fields.baby_seats_medium.label : null}</div>
                            <div className="form-select">
                                <select className="childSelect"
                                    style={{ backgroundImage: "url('/images/mobile/childSeat-2-icon.svg')" }}
                                    onChange={event => {
                                        this.setState(
                                            { order: { ...order, child_medium: event.target.value <= freeChildSeats ? event.target.value : freeChildSeats } }
                                        )
                                    }}
                                    value={order.child_medium}>
                                    <option value={null} ></option>
                                    {freeChSelOpts.map((p, i) => (
                                        <option value={p} key={i}>{p}</option>
                                    ))}
                                </select>
                                <div className="select-caret" />
                            </div>
                            <div className="form-input-desc">{translate.orderForm.fields ? translate.orderForm.fields.baby_seats_medium.desc : null}</div>
                        </div>
                        : ''}
                    {freeChildSeats > 0 || order.child_big > 0 ?
                        <div className="form-row">
                            <div className="form-label">{translate.orderForm.fields ? translate.orderForm.fields.baby_seats_big.label : null}</div>
                            <div className="form-select">
                                <select className="childSelect"
                                    style={{ backgroundImage: "url('/images/mobile/childSeat-3-icon.svg')" }}
                                    onChange={event => {
                                        this.setState(
                                            { order: { ...order, child_big: event.target.value <= freeChildSeats ? event.target.value : freeChildSeats } }
                                        )
                                    }}
                                    value={order.child_big}>
                                    <option value={null} ></option>
                                    {freeChSelOpts.map((p, i) => (
                                        <option value={p} key={i}>{p}</option>
                                    ))}
                                </select>
                                <div className="select-caret" />
                            </div>
                            <div className="form-input-desc">{translate.orderForm.fields ? translate.orderForm.fields.baby_seats_big.desc : null}</div>
                        </div>
                        : ''}
                </div>
            )
        }

        let backTransfer = null;
        if (order.return_transfer) {
            backTransfer = (
                <div>
                    <div className="form-row">
                        <div className="form-label">{translate.orderForm.fields ? translate.orderForm.fields.return_date.label : null}</div>
                        <input onChange={event => {
                            this.setState(
                                { order: { ...order, return_date: event.target.value } }
                            )
                        }
                        } className={'form-control text-field ' + (errors.return_date ? 'error-field' : '')}
                            style={{ backgroundImage: "url('/images/mobile/calendar-icon.svg')" }} type="date" />
                    </div>
                    <div className="form-row">
                        <TimePicker
                            showSecond={false}
                            inputReadOnly={true}
                            allowEmpty={false}

                            popupClassName="timepickerPopup"
                            onChange={value => {
                                this.setState({ order: { ...order, return_time: value.format('HH:mm') } })
                            }}
                            className={(errors.pick_up_time ? 'error-field' : '')}
                            style={{ backgroundImage: "url('/images/mobile/clock-icon.svg')" }} />
                        {/* <input className={'form-control text-field ' + (errors.return_time ? 'error-field' : '')}
                            style={{ backgroundImage: "url('/images/mobile/clock-icon.svg')" }} type="time" /> */}
                    </div>
                </div>
            );
        }

        return (
            <div className='pageContent'>
                {this.state.orderConfirm && this.state.orderToken ? (<Redirect to={'/' + this.state.lang + '/order-confirm?order=' + this.state.orderToken} />) : ('')}
                <h2 className='page-title'>{translate.orderForm.title}</h2>
                <p className='text-center'>{translate.orderForm.subTitle}</p>
                <div className='order-form-desc'>{transfer.from.title} - {transfer.to.title}</div>
                {transfer.duration && transfer.duration.hours !== '00:00' ? (
                    <div className='order-form-desc'>{translate.orderForm.duration} {transfer.duration.hours}</div>) : ''}
                <div className="order-form">
                    {transfer.type === 's-p' ?
                        (
                            <div className="form-row">
                                <div className="form-label">
                                    <b>1.</b> {translate.orderForm.fields ? translate.orderForm.fields.flight.label : null}
                                </div>
                                <input onChange={event => {
                                    this.setState({
                                        order: { ...order, flight: event.target.value }
                                    }
                                    )
                                }
                                } className={'form-control text-field ' + (errors.flight ? 'error-field' : '')}
                                    type="text" placeholder="AB 1234" />
                            </div>
                        )
                        :
                        (
                            <div className="form-row">
                                <div className="form-label">
                                    <b>1.</b> {translate.orderForm.fields ? translate.orderForm.fields.pickup_point.label : null}
                                </div>
                                <input onChange={event => {
                                    this.setState({
                                        order: { ...order, pick_up: event.target.value }
                                    }
                                    )
                                }
                                }
                                    className={'form-control text-field ' + (errors.pick_up ? 'error-field' : '')}
                                    type="text" placeholder="Адрес" />
                                <div className="form-input-desc">{translate.orderForm.fields ? translate.orderForm.fields.pickup_point.desc : null}</div>
                            </div>
                        )
                    }

                    <div className="form-row">
                        <div className="form-label">
                            <b>2.</b> {translate.orderForm.fields ? translate.orderForm.fields.date.label : null} {transfer.type === 's-p' ? translate.orderForm.fields ? translate.orderForm.fields.date.desc : '' : ''}
                        </div>
                        <input onChange={event => {
                            this.setState({ order: { ...order, pick_up_date: event.target.value } })
                        }}
                            className={'form-control text-field ' + (errors.pick_up_date ? 'error-field' : '')}
                            style={{ backgroundImage: "url('/images/mobile/calendar-icon.svg')" }} type="date" />
                    </div>
                    <div className="form-row">
                        <TimePicker
                            showSecond={false}
                            inputReadOnly={true}
                            allowEmpty={false}
                            placement='bottomLeft'

                            popupClassName="timepickerPopup"
                            onChange={value => {
                                this.setState({ order: { ...order, pick_up_time: value.format('HH:mm') } })
                            }}
                            className={(errors.pick_up_time ? 'error-field' : '')}
                            style={{ backgroundImage: "url('/images/mobile/clock-icon.svg')" }} />
                        {/* <input onChange={event => {
                            this.setState({ order: { ...order, pick_up_time: event.target.value } })
                        }}
                            className={'form-control text-field ' + (errors.pick_up_time ? 'error-field' : '')}
                            style={{ backgroundImage: "url('/images/mobile/clock-icon.svg')" }} type="time" /> */}

                    </div>
                    <div className="form-row">
                        <div className="form-label">
                            <b>3.</b> {translate.orderForm.fields ? translate.orderForm.fields.pax.label : null}
                        </div>
                        <div className="form-select">
                            <select onChange={event => {
                                // let oPax = order.pax;
                                this.changePax(event.target.value);

                            }
                            } className="paxSelect"
                                style={{ backgroundImage: "url('/images/mobile/people-icon.svg')" }}>
                                {tmpPax.map((p, i) => (
                                    <option value={p} key={i}>{p}</option>
                                ))}
                            </select>
                            <div className="select-caret" />
                        </div>
                    </div>

                    {
                        order.pax > 1 ? (
                            <div className="form-row">
                                <div style={{ display: 'table' }}>
                                    <div style={{ display: 'table-cell' }}>
                                        <Switch
                                            checked={order.child}
                                            className="switch"
                                            onChange={checked => {
                                                this.setState({ order: { ...order, child: checked } })
                                            }}
                                        />
                                    </div>
                                    <div style={{ display: 'table-cell' }}>
                                        <div className="switch-label">{translate.orderForm.fields ? translate.orderForm.fields.baby_seats.label : null}</div>
                                    </div>
                                </div>
                            </div>
                        ) : ''
                    }
                    {childSelects}
                    <div className="form-row">
                        <div style={{ display: 'table' }}>
                            <div style={{ display: 'table-cell' }}>
                                <Switch
                                    checked={order.return_transfer}
                                    className="switch"
                                    onChange={checked => {
                                        this.setState({ order: { ...order, return_transfer: checked } })
                                    }}
                                />
                            </div>
                            <div style={{ display: 'table-cell' }}>
                                <div className="switch-label">{translate.orderForm.fields ? translate.orderForm.fields.return.label : null}</div>
                            </div>
                        </div>
                    </div>
                    {backTransfer}
                    {transfer.type !== 'p-s' ?
                        (
                            <div className="form-row">
                                <div className="form-label">
                                    <b>4.</b> {translate.orderForm.fields ? translate.orderForm.fields.drop_off.label : null}
                                </div>
                                <input onChange={event => {
                                    this.setState({
                                        order: { ...order, drop_off: event.target.value }
                                    }
                                    )
                                }
                                } className={'form-control text-field ' + (errors.drop_off ? 'error-field' : '')}
                                    type="text" placeholder='Radison Hotel' />
                            </div>
                        ) : ('')}

                    <div className="form-row">
                        <div className="form-label">
                            <b>5.</b> {translate.orderForm.fields ? translate.orderForm.fields.customer_name.label : null}
                        </div>
                        <input onChange={event => {
                            let plate = order.plate;
                            this.setState({
                                order: {
                                    ...order,
                                    contact_name: event.target.value,
                                    plate: this.state.plateChanged ? plate : event.target.value
                                }
                            }
                            )
                        }
                        } className={'form-control text-field ' + (errors.contact_name ? 'error-field' : '')}
                            type="text" placeholder='Alexander Ivanov' />
                    </div>
                    {transfer.type === 's-p' ?
                        (
                            <div className="form-row">
                                <div className="form-label">
                                    <b>6.</b> {translate.orderForm.fields ? translate.orderForm.fields.plate.label : null}
                                </div>
                                <input onChange={event => {
                                    this.setState({
                                        plateChanged: true
                                    }
                                    )
                                    this.setState({
                                        order: { ...order, plate: event.target.value }
                                    }
                                    )
                                }
                                } className="form-control text-field" type="text" placeholder={translate.orderForm.fields ? translate.orderForm.fields.plate.desc : null} value={this.state.order.plate} />
                            </div>
                        ) : ('')}

                    <div className="form-row">
                        <div className="form-label">
                            <b>7.</b> {translate.orderForm.fields ? translate.orderForm.fields.customer_email.label : null}
                        </div>
                        <input onChange={event => {
                            this.setState({
                                order: { ...order, contact_email: event.target.value }
                            }
                            )
                        }
                        } className={'form-control text-field ' + (errors.contact_email ? 'error-field' : '')}
                            type="text" placeholder='noname@gmail.com' />
                    </div>
                    <div className="form-row">
                        <div className="form-label">
                            <b>8.</b> {translate.orderForm.fields ? translate.orderForm.fields.customer_phone.label : null}
                        </div>
                        <input onChange={event => {
                            this.setState({
                                order: { ...order, contact_phone: event.target.value }
                            }
                            )
                        }
                        } className={'form-control text-field ' + (errors.contact_phone ? 'error-field' : '')}
                            type="text" placeholder='+38 (099) 999-99-99' />
                    </div>
                    <div className="form-row">
                        <div className="form-label">
                            <b>9.</b> {translate.orderForm.fields ? translate.orderForm.fields.additional.label : null}
                        </div>
                        <textarea onChange={event => {
                            this.setState({
                                order: { ...order, comment: event.target.value }
                            }
                            )
                        }
                        } className="form-control text-field"
                            placeholder={translate.orderForm.fields ? translate.orderForm.fields.additional.desc : null}
                            style={{ height: 200 }} />
                    </div>
                    <div className='form-desc' dangerouslySetInnerHTML={{ __html: translate.orderForm.notice }}></div>
                    <br />
                    <div className="text-center">
                        <button onClick={this.orderFormSubmit} className="btn btn-green">{translate.orderForm.fields ? translate.orderForm.fields.order_btn.label : null}</button>
                    </div>
                    <br />
                    <div className='form-desc text-center'><a href="/terms-and-conditions" target="_blank">{translate.orderForm.conditions}</a></div>
                </div>
            </div>
        );
    }
}

export default OrderFrom;
