import React, { Component } from 'react';
import $ from 'jquery';
import qs from 'query-string';
import { baseUrl } from "../constants";
import { NavLink } from "react-router-dom";

class Transfer extends Component {

    state = {
        lang: this.props.match.params.lang,
        apiUrl: baseUrl + (this.props.match.params.lang !== 'ru' ? this.props.match.params.lang + '/' : ''),

        fromId: null,
        toId: null,
        vehicleId: null,
        transfer: {
            from: {
                id: null,
                title: null,
            },
            to: {
                id: null,
                title: null,
            },
            seatPrice: null,
            vehicles: [],
        },
        translate: {},
    };
    componentDidMount() {
        let urlParams = qs.parse(this.props.location.search);
        if (urlParams.from_id) {
            this.setState({ fromId: urlParams.from_id });
        }
        if (urlParams.to_id) {
            this.setState({ toId: urlParams.to_id });
        }
        this.getData();
        $('.navbar-collapse').collapse('hide');
    };

    async getData() {
        $('#loader').show();
        await fetch(this.state.apiUrl + 'api/transfer-offers' + this.props.location.search)
            .then(response => response.json())
            .then(data => {
                $('#loader').hide();
                this.setState({ transfer: data.transfer });
                this.setState({ translate: data.translations });
            });
    };

    render() {
        const { transfer, translate, lang } = this.state;
        let groupTransfer = null;
        if (transfer.seatPrice && transfer.seatPrice > 0) {
            groupTransfer = (
                <div className="transfer-offer">
                    <div className="offer-title">{translate.groupTitle}</div>
                    <img src={'/images/mobile/vehicles/vehicle-group.png'} className="d-block w-100"
                        alt="..." />
                    <div className="vehicle-name">Mercedes Vito</div>
                    {/* <div className="vehicle-desc">{translate.groupDescription}</div> */}
                    <div className="vehicle-params">
                        <div className="vehicle-param-item">
                            <img className="params-icon" src="/images/mobile/vehicle-person.svg" alt="" />
                            <div className="params-value">{translate.groupPax}</div>
                        </div>
                        <div className="vehicle-param-item">
                            <img className="params-icon" src="/images/mobile/vehicle-luggage.svg" alt="" />
                            <div className="params-value">{translate.groupBaggage}</div>
                        </div>
                    </div>
                    <div className="offer-price">{transfer.seatPrice} €</div>
                    <div className="text-center">
                        <NavLink
                            to={
                                '/' + lang +
                                '/transfer-order?' +
                                'from=' +
                                transfer.from.id +
                                '&' +
                                'to=' +
                                transfer.to.id +
                                '&' +
                                'vehicle=0'
                            }
                            className="btn btn-green">{translate.orderBtn}</NavLink>
                    </div>
                </div>);
        }
        let Vehicle = (props) => (
            <div className="transfer-offer">
                <div className="offer-title">{props.vehicle.title}</div>
                <img src={'/images/mobile/vehicles/vehicle-' + props.vehicle.id + '.png'} className="d-block w-100"
                    alt="..." />
                <div className="vehicle-name">{props.vehicle.example}</div>
                <div className="vehicle-desc">{props.vehicle.description}</div>
                <div className="vehicle-params">
                    <div className="vehicle-param-item">
                        <img className="params-icon" src="/images/mobile/vehicle-person.svg" alt="" />
                        <div className="params-value">{props.vehicle.pax}</div>
                    </div>
                    <div className="vehicle-param-item">
                        <img className="params-icon" src="/images/mobile/vehicle-luggage.svg" alt="" />
                        <div className="params-value">{props.vehicle.baggage}</div>
                    </div>
                </div>
                <div className="offer-price">{props.vehicle.price > 0 ? props.vehicle.price + ' €' : props.vehicle.price}</div>
                <div className="text-center">
                    <NavLink
                        to={
                            '/' + lang +
                            '/transfer-order?' +
                            'from=' +
                            transfer.from.id +
                            '&' +
                            'to=' +
                            transfer.to.id +
                            '&' +
                            'vehicle=' +
                            props.vehicle.id
                        }
                        className="btn btn-green">{translate.orderBtn}</NavLink>
                </div>
            </div>
        );

        return (
            <div className='pageContent'>
                <h2 className='transfer-title'>{translate.directionPrefix} {transfer.from.title} – {transfer.to.title}</h2>
                {groupTransfer}
                {transfer.vehicles.map((vehicle, index) => (
                    <Vehicle vehicle={vehicle} key={index} />
                ))}
            </div>
        );
    }
}

export default Transfer;
