import React, { Component } from 'react';
import { baseUrl } from '../constants';
import $ from 'jquery';
class Faq extends Component {
    state = {
        lang: this.props.match.params.lang,
        apiUrl: baseUrl + (this.props.match.params.lang !== 'ru' ? this.props.match.params.lang + '/' : ''),

        titles: {},
        content: {}
    }
    componentDidMount() {
        this.initPage();
        $('.navbar-collapse').collapse('hide');
    }
    initPage() {
        $('#loader').show();
        fetch(this.state.apiUrl + 'api/init-mobile-page/faq')
            .then(response => response.json())
            .then(data => {
                $('#loader').hide();
                this.setState({ titles: data.translations });
                this.setState({ content: data.content });
            });
    }
    render() {
        const { titles, content } = this.state;
        return (
            <div className='pageContent'>
                <h1 className='page-title'>{titles.title}</h1>
                <p className='text-center'>{content.general}</p>
                <div className="accordion" id="accordionFaq">
                    {content.faq_items ? content.faq_items.map((faqItem, index) => (
                        <div className="card" key={index}>
                            <div className="card-header" id={"heading-" + index}>
                                <h2 className="mb-0">
                                    <button className={index === 0 ? 'btn btn-link' : 'btn btn-link collapsed'} type="button" data-toggle="collapse"
                                        data-target={"#collapse" + index} aria-expanded={index === 0 ? true : false} aria-controls={"#collapse" + index}>
                                        {faqItem.question}
                                </button>
                                </h2>
                            </div>
                            <div id={"collapse" + index} className={index === 0 ? 'collapse show' : 'collapse'} data-parent="#accordionFaq">
                                <div className="card-body" dangerouslySetInnerHTML={{ __html: faqItem.answer }}></div>
                            </div>
                        </div>
                    )) : ''}
                   
                </div>
            </div>
        );
    }
}

export default Faq;
