/* eslint-disable no-useless-constructor */
import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import App from './App'
import Home from './containers/Home'
import About from './containers/About'
import Vehicles from './containers/Vehicles'
import Directions from './containers/Directions'
import Benefits from './containers/Benefits'
import Reviews from './containers/Reviews'
import Faq from './containers/Faq'
import Transfer from './containers/Transfer'
import Contact from './containers/Contact'
import OrderForm from './containers/OrderForm'
import OrderConfirm from './containers/OrderConfirm'
import Terms from './containers/Terms'

const supportedLanguages = ["en", "ru"];
const routes = (
  <Router>

    <Route render={props => (
      <Switch>
        <Route path="/:lang/about" render={props =>
          <App lang={props.match.params.lang}>
            <About {...props} />
          </App>
        } />
        <Route path="/:lang/vehicles" render={props =>
          <App lang={props.match.params.lang}>
            <Vehicles {...props} />
          </App>
        } />
        <Route path="/:lang/directions" render={props =>
          <App lang={props.match.params.lang}>
            <Directions {...props} />
          </App>
        } />
        <Route path="/:lang/benefits" render={props =>
          <App lang={props.match.params.lang}>
            <Benefits {...props} />
          </App>
        } />
        <Route path="/:lang/reviews" render={props =>
          <App lang={props.match.params.lang}>
            <Reviews {...props} />
          </App>
        } />
        <Route path="/:lang/faq" render={props =>
          <App lang={props.match.params.lang}>
            <Faq {...props} />
          </App>
        } />
        <Route path="/:lang/transfer" render={props =>
          <App lang={props.match.params.lang}>
            <Transfer {...props} />
          </App>
        } />
        <Route path="/:lang/transfer-order" render={props =>
          <App lang={props.match.params.lang}>
            <OrderForm {...props} />
          </App>
        } />
        <Route path="/:lang/terms-and-conditions" render={props =>
          <App lang={props.match.params.lang}>
            <Terms {...props} />
          </App>
        } />
        <Route path="/:lang/order-confirm" render={props =>
          <App lang={props.match.params.lang}>
            <OrderConfirm {...props} />
          </App>
        } />
        <Route path="/:lang/contacts" render={props =>
          <App lang={props.match.params.lang}>
            <Contact {...props} />
          </App>
        } />

        {/* <Route path="/:lang/vehicles" component={Vehicles} /> */}
        {/* <Route path="/:lang/directions" component={Directions} /> */}
        {/* <Route path="/:lang/benefits" component={Benefits} /> */}
        {/* <Route path="/:lang/reviews" component={Reviews} /> */}
        {/* <Route path="/:lang/faq" component={Faq} /> */}
        {/* <Route path="/:lang/transfer" component={Transfer} /> */}
        {/* <Route path="/:lang/transfer-order" component={OrderForm} /> */}
        {/* <Route path="/:lang/terms-and-conditions" component={Terms} /> */}
        {/* <Route path="/:lang/order-confirm" component={OrderConfirm} /> */}
        {/* <Route path="/:lang/contacts" component={Contact} /> */}
        <Route
          path="/:lang"
          render={props =>
            supportedLanguages.includes(props.match.params.lang) ? (
              <App lang={props.match.params.lang}><Home {...props} /></App>
            ) : (
                <Redirect to={`/en/${props.match.params.lang}`} />
              )
          }
        />
        <Route
          path="/"
          render={props => <Redirect to='/en' />}
        />
      </Switch>
    )
    } />
  </Router >
);

export default routes;
