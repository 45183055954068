import React, { Component } from 'react';
import { baseUrl } from '../constants';
import $ from 'jquery';

class Benefits extends Component {
    state = {
        lang: this.props.match.params.lang,
        apiUrl: baseUrl + (this.props.match.params.lang !== 'ru' ? this.props.match.params.lang + '/' : ''),

        titles: {},
        content:{}
    }
    componentDidMount() {
        this.initPage();
        $('.navbar-collapse').collapse('hide');
    }
    initPage() {
        $('#loader').show();
        fetch(this.state.apiUrl + 'api/init-mobile-page/advantages')
            .then(response => response.json())
            .then(data => {
                $('#loader').hide();
                this.setState({ titles: data.translations });
                this.setState({ content: data.content });
            });
    };
    render() {
        const { titles, content } = this.state;
        return (
            <div className='pageContent'>
                <h1 className='page-title'>{titles.title}</h1>
                <div className="benefits-title">{content.general}</div>
                <div className="block-with-icon">
                    <img src="/images/mobile/mercedes.svg" alt=""/>
                    <p><b>{content.advantages_1}</b></p>
                </div>
                <div className="block-with-icon">
                    <img src="/images/mobile/driver.svg" alt="" />
                    <p><b>{content.advantages_2}</b></p>
                </div>
                <div className="block-with-icon">
                    <img src="/images/mobile/cash-payment.svg" alt="" />
                    <p><b>{content.advantages_3}</b></p>
                </div>
                <div className="block-with-icon">
                    <img src="/images/mobile/child.svg" alt="" />
                    <p><b>{content.advantages_4}</b></p>
                </div>
                <div className="block-with-icon">
                    <img src="/images/mobile/airplane.svg" alt="" />
                    <p><b>{content.advantages_5}</b></p>
                </div>
            </div>
        );
    }
}

export default Benefits;
