import React, {Component} from 'react';
import $ from 'jquery';
import {baseUrl} from "../constants";

class Benefits extends Component {
    state = {
        lang: this.props.match.params.lang,
        apiUrl: baseUrl + (this.props.match.params.lang !== 'ru' ? this.props.match.params.lang + '/' : ''),

        title: null,
        content: null
    }
    componentDidMount() {
        $('.navbar-collapse').collapse('hide');
        this.initPage();
    }
    initPage() {
        $('#loader').show();
        fetch(this.state.apiUrl + 'api/init-mobile-page/terms-and-conditions')
            .then(response => response.json())
            .then(data => {
                $('#loader').hide();
                this.setState({title: data.title});
                this.setState({content: data.content});
            });
    };

    render() {
        return (
            <div className='pageContent'>
                <h1 className='page-title'>{this.state.title}</h1>
                <div className="content" dangerouslySetInnerHTML={{__html: this.state.content}}></div>
            </div>
        );
    }
}

export default Benefits;
